import cx from 'classnames'
import { useCallback, useContext, useMemo } from 'react'

import { AnalyticsEventName, getProductEventPayload } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { LineItem } from '@lib/cart/types'
import { getProductListingThumbnails } from '@lib/product-images'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import SimpleLink from '@components/simple-link'
import Photo from '@components/photo'
import ProductPrice from '@blocks/product/product-price'
import ProductCounter from '@blocks/product/product-counter'

interface CartItemProps {
  item: LineItem
  className?: string
}

const CartItem = ({ item, className }: CartItemProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { toggleCart, updateCartItem, removeItemFromCart } =
    useContext(CartContext)
  const { currencyCode } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const thumbnailImage = useMemo(() => {
    const listingPhotos = !!item.product.inheritCartPhotos
      ? item.product.listingPhotos
      : item.product.cartPhotos

    return getProductListingThumbnails(
      item.product.galleryPhotos ?? [],
      listingPhotos ?? [],
      item.options,
    )
  }, [item.product, item.options])

  const productUrl = item.product.slug.current
    ? getLinkPageUrl('product', item.product.slug.current, {
        variant: `${item.variantID}`,
      })
    : null

  const handleUpdate = useCallback(
    (newQuantity: number, oldQuantity: number) => {
      updateCartItem(item.lineId, newQuantity)

      if (newQuantity > oldQuantity) {
        const eventPayload = getProductEventPayload(
          item.product.title,
          [
            {
              variantId: item.variantID,
              options: item.options,
              price: item.price,
              comparePrice: item.comparePrice,
              quantity: newQuantity - oldQuantity,
            },
          ],
          currencyCode,
        )
        triggerAnalyticsEvent(AnalyticsEventName.AddToCart, eventPayload)
      }

      if (newQuantity < oldQuantity) {
        const eventPayload = getProductEventPayload(
          item.product.title,
          [
            {
              variantId: item.variantID,
              options: item.options,
              price: item.price,
              comparePrice: item.comparePrice,
              quantity: oldQuantity - newQuantity,
            },
          ],
          currencyCode,
        )
        triggerAnalyticsEvent(AnalyticsEventName.RemoveFromCart, eventPayload)
      }
    },
    [currencyCode, item, updateCartItem, triggerAnalyticsEvent],
  )

  const handleClose = useCallback(() => {
    toggleCart(false)
  }, [toggleCart])

  const handleRemove = useCallback(() => {
    removeItemFromCart(item.lineId)

    const eventPayload = getProductEventPayload(
      item.product.title,
      [
        {
          variantId: item.variantID,
          options: item.options,
          price: item.price,
          comparePrice: item.comparePrice,
          quantity: item.quantity,
        },
      ],
      currencyCode,
    )
    triggerAnalyticsEvent(AnalyticsEventName.RemoveFromCart, eventPayload)
  }, [currencyCode, item, removeItemFromCart, triggerAnalyticsEvent])

  return (
    <div className={cx('grid grid-cols-[auto,1fr] gap-3', className)}>
      {!!thumbnailImage && (
        <Photo
          image={thumbnailImage}
          sizes="(min-width: 768px) 400px, 35vw"
          className="max-w-20 xs:max-w-24"
        />
      )}

      <div className="flex flex-col">
        <div className="grid grid-cols-2 min-h-full gap-x-3 gap-y-6 pb-3">
          <div>
            <h3 className="text-xs leading-normal">
              {!!productUrl && (
                <SimpleLink
                  href={productUrl}
                  onClick={handleClose}
                  onBeforeInput={handleClose}
                  tabIndex={0}
                  role="link"
                >
                  {item.product.title}
                </SimpleLink>
              )}
              {!productUrl && <>{item.product.title}</>}
            </h3>

            {item.options.map((option) => (
              <p key={option.name} className="text-xs uppercase">
                {option.name}: {option.value}
              </p>
            ))}
          </div>

          <ProductPrice
            price={item.price}
            className="text-xs leading-normal self-start justify-self-end"
          />

          <ProductCounter
            id={`${item.variantID}`}
            defaultCount={item.quantity}
            onUpdate={handleUpdate}
            className="justify-self-start"
            // TODO: Set max
          />

          <button
            onClick={handleRemove}
            className={cx(
              'text-xs text-gray-dark uppercase underline hover:no-underline self-center justify-self-end',
            )}
          >
            {strings.buttonRemove}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CartItem
