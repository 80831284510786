import { useContext, useEffect, useMemo, useState } from 'react'

import { type SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { LanguageContext } from '@lib/language-context'
import { useProductWithInventory } from '@lib/product'
import { type ProductCombinedListing } from './types'

/**
 * Active combined listing hook.
 */
export const useActiveCombinedListing = (
  originalCombinedListing?: ProductCombinedListing,
) => {
  const [combinedListing, setCombinedListing] =
    useState<ProductCombinedListing>()

  const activeCombinedListing = useMemo(
    () => combinedListing ?? originalCombinedListing,
    [originalCombinedListing, combinedListing],
  )

  useEffect(() => {
    setCombinedListing(originalCombinedListing)
  }, [originalCombinedListing])

  return activeCombinedListing
}

/**
 * Active combined listing product hook.
 */
export const useCombinedListingActiveProduct = (
  combinedListing?: ProductCombinedListing,
  variant?: SanityProductVariantFragment,
) => {
  const { locale } = useContext(LanguageContext)

  // Active product
  const activeProduct = useMemo(() => {
    if (!combinedListing || !variant) {
      return
    }

    // Find active product from combined listing products based on active variant
    return combinedListing.products.find((product) =>
      product.variants?.some(
        (productVariant) => productVariant.variantID === variant.variantID,
      ),
    )
  }, [combinedListing, variant])

  const activeProductWithInventory = useProductWithInventory(
    locale,
    activeProduct,
  )

  return activeProductWithInventory
}
