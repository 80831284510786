import { type SanityColor } from '@data/sanity/queries/types/color'
import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import RadioItem from '@components/radio-item'
import Swatch from '@components/swatch'
import ProductOptionThumbnail from './product-option-thumbnail'

interface ProductOptionProps {
  value: string
  thumbnail?: SanityImageFragment
  thumbnailWidth?: number
  color?: SanityColor
  colorLabel?: string
  isActive?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
}

const ProductOption = ({
  value,
  thumbnail,
  thumbnailWidth,
  color,
  colorLabel,
  isActive = false,
  onMouseEnter,
  onMouseLeave,
  className,
}: ProductOptionProps) => {
  return (
    <RadioItem
      value={value}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      {!!thumbnail && !!thumbnailWidth && (
        <ProductOptionThumbnail
          thumbnail={thumbnail}
          width={thumbnailWidth}
          isActive={isActive}
        />
      )}

      {!thumbnail && !!color && (
        <Swatch color={color} label={colorLabel} isActive={isActive} />
      )}

      {!thumbnail && !color && <>{value}</>}
    </RadioItem>
  )
}

export default ProductOption
