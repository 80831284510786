import { v4 as uuidv4 } from 'uuid'

export interface ErrorMessages {
  [key: string]: string
}

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(records?: any[], values?: any[] | any) {
  if (!records) {
    return false
  }

  return records.some((object) => {
    for (const key in object) {
      if (
        values &&
        key in values &&
        object[key] != (values as Record<string, unknown>)[key]
      ) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Formats a value by adding thousands separators.
 */
export const addThousandSeparators = (
  value: string,
  thousandSeparator: string,
) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units and adds tax. Adds trailing zeros if needed.
 */
export const getPrice = (
  minorUnits: number,
  taxRate: number,
  hasTrailingZeros = false,
  thousandSeparator = ',',
) => {
  const price = (minorUnits / 100) * (1 + taxRate)

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const parts = price.toFixed(2).split('.')
  parts[0] = addThousandSeparators(parts[0], thousandSeparator)

  return `${parts.join('.')}`
}

/**
 * Gets price number from value in minor units.
 */
export const getPriceNumber = (minorUnits: number) =>
  Number(getPrice(minorUnits, 0, true, ''))

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(parameter: T | T[]): T {
  return Array.isArray(parameter) ? parameter[0] : parameter
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: T | T[] | null,
): T | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null,
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 > number2 ? 1 : number1 < number2 ? -1 : 0

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)

/**
 * Generates all combinations from multiple arrays.
 * E.g., getAllCombinations(['a', 'b'], ['1', '2']) returns [['a', '1'], ['a', '2'], ['b', '1'], ['b', '2']].
 */
export const getAllCombinations = (...arrays: string[][]): string[][] => {
  const initialValue: string[][] = [[]]

  return [...arrays].reduce(
    (resultArrays, array) =>
      resultArrays
        .map((resultArray) =>
          array.map((arrayValue) => resultArray.concat(arrayValue)),
        )
        .reduce(
          (newResultArrays, arraysItem) => newResultArrays.concat(arraysItem),
          [],
        ),
    initialValue,
  )
}

/**
 * Generates a random string based on UUID.
 */
export const getRandomString = () => Buffer.from(uuidv4()).toString('base64')

/**
 * Gets published and draft document IDs from given ID.
 */
export const getDocumentIds = (id: string) => {
  const publishedId = id.replace(/^drafts\./, '')
  const draftId = `drafts.${publishedId}`

  return [publishedId, draftId] as const
}

/**
 * Determines if JavaScript object is numeric.
 */
export const isNumeric = (object: any) => {
  const normalizedObject =
    typeof object === 'string' ? object.replace(/,/g, '') : object

  return (
    !isNaN(parseFloat(normalizedObject)) &&
    isFinite(normalizedObject) &&
    Object.prototype.toString.call(normalizedObject).toLowerCase() !==
      '[object array]'
  )
}

/**
 * Gets an object based on source object excluding specified keys.
 */
export const getObjectExcludingKeys = <T extends {}, K extends keyof any>(
  object: T,
  keys: string[],
) => {
  const entries = Object.entries(object)
  const filteredEntries = entries.filter(([key]) => !keys.includes(key))
  return Object.fromEntries(filteredEntries) as Omit<T, K>
}

/**
 * Wraps a number around minimum and maximum value.
 */
export const wrap = (value: number, length: number) => {
  if (value < 0) {
    value = length + (value % length)
  }

  if (value >= length) {
    return value % length
  }

  return value
}
