import { useContext, useEffect, useState } from 'react'

import { type SanityBlogSettings } from '@data/sanity/queries/types/blog'
import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { type SanitySeo } from '@data/sanity/queries/types/seo'
import { type Locale } from './language'
import { LanguageContext } from './language-context'
import { MetadataContext } from './metadata-context'
import { getPageUrl, PageType } from './routes'

/**
 * Blog canonical URL hook.
 */
export const useBlogCanonicalUrl = (
  pageType: PageType,
  slug: string,
  blogSettings?: SanityBlogSettings,
) => {
  const { locale } = useContext(LanguageContext)

  const [canonicalUrl, setCanonicalUrl] = useState<string>()

  useEffect(() => {
    // Check if blog inheritance is set
    if (
      typeof window === 'undefined' ||
      !blogSettings?.inheritContent ||
      !blogSettings?.sourceLocale
    ) {
      return
    }

    const origin = window.location.origin
    const pageUrl = getPageUrl(
      blogSettings.sourceLocale as Locale,
      pageType,
      slug,
    )
    setCanonicalUrl(`${origin}${pageUrl}`)
  }, [locale, pageType, slug, blogSettings])

  return canonicalUrl
}

/**
 * Gets metadata for product.
 */
const getProductMetadata = (product?: SanityProductFragment) => {
  if (!product?.seo) {
    return
  }

  const seo: SanitySeo = {
    metaTitle: product.seo.metaTitle,
    metaDesc: product.seo.metaDesc,
    shareTitle: product.seo.shareTitle,
    shareDesc: product.seo.shareDesc,
    shareGraphic: product.seo.shareGraphic,
  }
  return seo
}

/**
 * Gets metadata for product variant.
 */
const getProductVariantMetadata = (variant?: SanityProductVariantFragment) => {
  if (!variant?.seo) {
    return
  }

  const seo: SanitySeo = {
    metaTitle: variant.seo.metaTitle,
    metaDesc: variant.seo.metaDesc,
    shareTitle: variant.seo.shareTitle,
    shareDesc: variant.seo.shareDesc,
    shareGraphic: variant.seo.shareGraphic,
  }

  return seo
}

/**
 * Product page variant metadata hook.
 */
export const useVariantMetadata = (
  product?: SanityProductFragment,
  variant?: SanityProductVariantFragment,
) => {
  const { setMetadataOverride } = useContext(MetadataContext)

  // Set product variant metadata override
  useEffect(() => {
    const productMetadata = getProductMetadata(product)
    const productVariantMetadata = getProductVariantMetadata(variant)
    setMetadataOverride(productVariantMetadata ?? productMetadata ?? null)

    return () => {
      setMetadataOverride(null)
    }
  }, [product, setMetadataOverride, variant])
}
