import {
  type HTMLAttributes,
  type KeyboardEvent,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { wrap } from '@lib/helpers'
import { RadioGroupContext, RadioItemContext } from '@lib/radio-context'

type RadioItemProps = HTMLAttributes<HTMLButtonElement> & {
  value: string
  title?: string
  children: ReactNode
}

const RadioItem = ({
  value: itemValue,
  title,
  children,
  onMouseEnter,
  onMouseLeave,
  className,
}: RadioItemProps) => {
  const { value, items, onChange } = useContext(RadioGroupContext)
  const { index } = useContext(RadioItemContext)

  const itemRef = useRef<HTMLButtonElement>(null)
  const isInitialMount = useRef(true)

  const isChecked = useMemo(() => value === itemValue, [itemValue, value])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    if (isChecked) {
      itemRef.current?.focus({
        preventScroll: true,
      })
    }
  }, [isChecked])

  const handleClick = useCallback(() => {
    if (onChange) {
      onChange(itemValue)
    }
  }, [itemValue, onChange])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!onChange || !items) {
        return
      }

      // Listen for one of our key presses
      let isKeyPressed = false

      switch (event.code) {
        case 'Space':
        case 'Enter': {
          onChange(itemValue)
          isKeyPressed = true
          break
        }

        case 'ArrowUp':
        case 'ArrowLeft': {
          const previousIndex = wrap(index - 1, items.length)
          onChange(items[previousIndex].props.value)
          isKeyPressed = true
          break
        }

        case 'ArrowDown':
        case 'ArrowRight': {
          const nextIndex = wrap(index + 1, items.length)
          onChange(items[nextIndex].props.value)
          isKeyPressed = true
          break
        }
      }

      // If one of our keys were pressed, prevent default behavior
      if (isKeyPressed) {
        event.stopPropagation()
        event.preventDefault()
      }
    },
    [index, items, itemValue, onChange],
  )

  return (
    <button
      ref={itemRef}
      role="radio"
      title={title}
      tabIndex={isChecked ? 0 : -1}
      aria-checked={isChecked}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={className}
    >
      {children}
    </button>
  )
}

export default RadioItem
