import {
  type SanityContentCarouselBlock,
  type SanityContentCarouselItem,
} from '@data/sanity/queries/types/blocks'

import Carousel from '@components/carousel'
import ProductCard from '@blocks/shop/product-card'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import Photo from '@components/photo'
import MuxVideo from '@components/video/mux-video'

interface ContentCarouselItemProps {
  item: SanityContentCarouselItem
}

type ContentCarouselProps = Pick<SanityContentCarouselBlock, 'items'> & {
  className?: string
}

const ContentCarouselItem = ({ item }: ContentCarouselItemProps) => {
  switch (item._type) {
    case 'blogPost': {
      const { blogPost } = item

      return <BlogPostCard post={blogPost} />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = item

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'photo': {
      const image = item

      return (
        <Photo
          image={{
            ...image,
            _type: 'figure',
          }}
        />
      )
    }

    case 'product': {
      const { product } = item

      return (
        <ProductCard product={product} showThumbnails showOption showPrice />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = item

      if (type === 'mux' && !!muxVideo) {
        return (
          <MuxVideo
            video={muxVideo}
            showControls={settings?.controls}
            autoplay={settings?.autoplay}
            loop={settings?.loop}
            muted={settings?.muted}
            customAspectRatio={aspectRatio}
          />
        )
      }

      return null
    }
  }
}

const ContentCarousel = ({ items, className }: ContentCarouselProps) => {
  return (
    <Carousel
      items={items?.map((item) => (
        <ContentCarouselItem key={item._key} item={item} />
      ))}
      className={className}
    />
  )
}

export default ContentCarousel
