import {
  type ClientBrowserParameters,
  type ShopifyAddToCartPayload,
  type ShopifyAnalytics,
  type ShopifyPageViewPayload,
  AnalyticsEventName as ShopifyAnalyticsEventName,
  getClientBrowserParameters,
  sendShopifyAnalytics,
} from '@shopify/hydrogen-react'
import { customAlphabet } from 'nanoid'

import { type SanityProductVariantOption } from '@data/sanity/queries/types/product'
import { type CurrencyCode } from '@data/shopify/storefront/types'
import { type Cart } from './cart/types'
import { getPriceNumber } from './helpers'

interface AnalyticsSettings {
  googleEvents: boolean
  shopifyEvents: boolean
}

interface ProductVariantEventInput {
  variantId: number
  options: SanityProductVariantOption[]
  price: number
  comparePrice: number
  quantity: number
}

export enum AnalyticsEventType {
  GoogleTagManager = 'Google Tag Manager',
  Shopify = 'Shopify',
}

export enum AnalyticsEventName {
  AddToCart = 'Add to cart',
  InitiateCheckout = 'Initiate checkout',
  NewsletterSignUp = 'Newsletter sign up',
  Pageview = 'Pageview',
  RemoveFromCart = 'Remove from cart',
  Signup = 'Signup',
  ViewProduct = 'View product',
}

export type EventPayload = Record<string, unknown>

export interface AnalyticsEvent {
  id: string
  type: AnalyticsEventType
  name: AnalyticsEventName
  eventPayload?: EventPayload
}

export type ShopifyEventPayload = ClientBrowserParameters & EventPayload

/**
 * Creates custom analytics events based on event name.
 */
export const getAnalyticsEvents = (
  eventName: AnalyticsEventName,
  eventPayload: EventPayload,
  settings: AnalyticsSettings,
) => {
  const nanoid = customAlphabet('1234567890abcdef', 16)

  const analyticsEvents: AnalyticsEvent[] = []
  const shopifyEventNames: string[] = [
    AnalyticsEventName.AddToCart,
    AnalyticsEventName.Pageview,
  ]

  if (settings.googleEvents) {
    analyticsEvents.push({
      id: nanoid(),
      type: AnalyticsEventType.GoogleTagManager,
      name: eventName,
      eventPayload: {
        pagePath: window.location.pathname,
        pageTitle: document.title,
        ...eventPayload,
      },
    })
  }

  if (settings.shopifyEvents && shopifyEventNames.includes(eventName)) {
    const clientBrowserParameters = getClientBrowserParameters()

    analyticsEvents.push({
      id: nanoid(),
      type: AnalyticsEventType.Shopify,
      name: eventName,
      eventPayload: {
        ...clientBrowserParameters,
        ...eventPayload,
      },
    })
  }

  return analyticsEvents
}

/**
 * Triggers an event in Google Tag Manager.
 */
export const triggerGoogleTagManagerEvent = (
  eventName: AnalyticsEventName,
  eventPayload?: EventPayload,
) => {
  const nanoid = customAlphabet('1234567890abcdef', 16)
  let dataLayerEvent: EventPayload = {
    event: eventName,
    eventId: eventPayload?.id ?? nanoid(),
  }

  if (eventPayload) {
    dataLayerEvent = {
      ...dataLayerEvent,
      ...eventPayload,
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerEvent)
}

/**
 * Triggers an event in Shopify.
 */
export const triggerShopifyEvent = async (
  eventName: AnalyticsEventName,
  shopifyEventPayload: ShopifyEventPayload,
  shopId?: string | null,
  currency?: CurrencyCode,
  cartId?: string,
  shopDomain?: string,
) => {
  switch (eventName) {
    case AnalyticsEventName.Pageview: {
      if (!shopId || !currency) {
        break
      }

      const payload: ShopifyPageViewPayload = {
        ...shopifyEventPayload,
        hasUserConsent: true,
        shopId,
        currency,
      }
      const event: ShopifyAnalytics = {
        eventName: ShopifyAnalyticsEventName.PAGE_VIEW,
        payload,
      }
      await sendShopifyAnalytics(event, shopDomain)
      break
    }

    case AnalyticsEventName.AddToCart: {
      if (!shopId || !currency || !cartId) {
        break
      }

      const payload: ShopifyAddToCartPayload = {
        ...shopifyEventPayload,
        hasUserConsent: true,
        shopId,
        currency,
        cartId,
      }
      const event: ShopifyAnalytics = {
        eventName: ShopifyAnalyticsEventName.ADD_TO_CART,
        payload,
      }
      await sendShopifyAnalytics(event, shopDomain)
      break
    }
  }
}

/**
 * Gets product-related analytics event payload.
 */
export const getProductEventPayload = (
  productTitle: string,
  variants: ProductVariantEventInput[],
  currencyCode?: CurrencyCode,
) => {
  const totalPrice = variants.reduce(
    (total, variant) => total + variant.price * variant.quantity,
    0,
  )

  const eventPayload: EventPayload = {
    currency: currencyCode,
    value: getPriceNumber(totalPrice),
    items: variants.map((variant, index) => ({
      index,
      item_id: variant.variantId,
      item_name: productTitle,
      item_variant: variant.options.map((option) => option.value)?.join(', '),
      price: getPriceNumber(variant.price),
      discount: variant.comparePrice
        ? getPriceNumber(variant.comparePrice - variant.price)
        : 0,
      quantity: variant.quantity,
    })),
  }

  return eventPayload
}

/**
 * Gets initiate checkout analytics event data.
 */
export const getInitiateCheckoutEventPayload = (
  cart: Cart,
  currencyCode?: CurrencyCode,
) => {
  const eventPayload: EventPayload = {
    currency: currencyCode,
    value: getPriceNumber(cart.subTotal),
    items: cart.lineItems.map((lineItem, index) => ({
      index,
      item_id: lineItem.variantID,
      item_name: lineItem.product.title,
      item_variant: lineItem.options?.map((option) => option.value)?.join(', '),
      price: getPriceNumber(lineItem.price),
      quantity: lineItem.quantity,
    })),
    variant_ids: cart.lineItems.map((lineItem) =>
      lineItem.variantID.toString(),
    ),
  }

  if (cart.automaticDiscount?.amount) {
    eventPayload.automatic_discount = {
      title: cart.automaticDiscount.title ?? '',
      amount: cart.automaticDiscount.amount / 100,
    }
  }

  return eventPayload
}
