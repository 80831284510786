import groq from 'groq'

import { simplePortableTextFragment } from './content'
import { imageFragment } from './image'

export const cartSettingsFragment = groq`
  ...,
  terms[] {
    ${simplePortableTextFragment}
  },
  paymentProviders[] {
    ${imageFragment}
  },
`

export const collectionGridCartFragment = groq`
  ...,
  filter {
    ...,
    groups[] {
      _key,
      slug,
      display,
      title,
      options[]->{
        slug,
        type,
        title,
        "color": color->color,
        "collection": linkedCollection->{
          "featuredProductIds": products[]->productID,
        },
      },
    },
  },
`

export const searchResultGridCartFragment = groq`
  paginationLimit,
`
