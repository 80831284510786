import { SanitySimpleGridModuleBlock } from '@data/sanity/queries/types/modules'

import Freeform from '@blocks/freeform'
import LinkBlock from '@blocks/link-block'
import ProductCard from '@blocks/shop/product-card'
import Video from '@components/video'
import Photo from '@components/photo'
import NestedSimpleGrid from '@blocks/nested-simple-grid'
import ProductCarousel from '@blocks/shop/product-carousel'

interface SimpleGridBlockProps {
  block: SanitySimpleGridModuleBlock
  photoSizes?: string
}

const SimpleGridBlock = ({ block, photoSizes }: SimpleGridBlockProps) => {
  switch (block._type) {
    case 'figure': {
      return <Photo image={block} imageClassName="w-full" />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'linkBlock': {
      const {
        link,
        text,
        textColor,
        backgroundType,
        mobilePhoto,
        photo,
        muxVideo,
      } = block

      return (
        <LinkBlock
          link={link}
          text={text}
          textColor={textColor}
          backgroundType={backgroundType}
          mobilePhoto={mobilePhoto}
          photo={photo}
          photoSizes={photoSizes}
          muxVideo={muxVideo}
        />
      )
    }

    case 'nestedSimpleGrid': {
      const { blocks } = block

      if (!blocks) {
        return null
      }

      return <NestedSimpleGrid blocks={blocks} />
    }

    case 'productCard': {
      const { product } = block

      return (
        <ProductCard product={product} showThumbnails showOption showPrice />
      )
    }

    case 'productCarousel': {
      const { title, products } = block

      return <ProductCarousel title={title} products={products} />
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = block

      return (
        <Video
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
        />
      )
    }
  }
}

export default SimpleGridBlock
