import cx from 'classnames'

import { type SanityAccordionsBlock } from '@data/sanity/queries/types/blocks'

import Accordion from '@components/accordion'
import SimplePortableText from '@components/simple-portable-text'

type AccordionsProps = Pick<SanityAccordionsBlock, 'items'> & {
  className?: string
}

const Accordions = ({ items, className }: AccordionsProps) => {
  return (
    <div className={cx('mb-12 last:mb-0', className)}>
      {items.map(({ _key, title, content }) => (
        <Accordion key={_key} id={_key} title={title} variant="content">
          <SimplePortableText content={content} className="rc" />
        </Accordion>
      ))}
    </div>
  )
}

export default Accordions
