import axios from 'axios'

import { type SanityCombinedListingSettings } from '@data/sanity/queries/types/modules'
import { type SanityProductFragmentWithScore } from '@data/sanity/queries/types/product'

export interface ProductSearchResult {
  products: SanityProductFragmentWithScore[]
  combinedListings: SanityCombinedListingSettings[]
  error?: string
}

/**
 * Cals API page and gets product search results.
 */
export const searchProducts = async (query: string, limit?: number) => {
  const result = await axios.get<ProductSearchResult>('/api/product/search', {
    params: {
      query,
      limit,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (result.data.error) {
    throw new Error(result.data.error)
  }

  return result.data
}
