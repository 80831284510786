import { type AriaRole, type ReactElement, type ReactNode } from 'react'
import flattenChildren from 'react-keyed-flatten-children'

import { RadioGroupProvider, RadioItemProvider } from '@lib/radio-context'

interface RadioGroupProps {
  value: string
  role?: AriaRole
  onChange: (value: string) => void
  children: ReactNode
  className?: string
}

const RadioGroup = ({
  value,
  role = 'radiogroup',
  onChange,
  children,
  className,
}: RadioGroupProps) => {
  const items = flattenChildren(children) as ReactElement[]

  return (
    <RadioGroupProvider value={value} items={items} onChange={onChange}>
      <div role={role} className={className}>
        {items.map((item, index) => (
          <RadioItemProvider key={item.key} index={index}>
            {item}
          </RadioItemProvider>
        ))}
      </div>
    </RadioGroupProvider>
  )
}

export default RadioGroup
