import cx from 'classnames'
import { contrastColor } from 'contrast-color'
import { type CSSProperties, type ReactNode } from 'react'

import { type SanityColor } from '@data/sanity/queries/types/color'

interface CSSPropertiesWithSwatch extends CSSProperties {
  '--swatchColor': string
  '--swatchBorder': string
}

interface SwatchProps {
  color: SanityColor
  label?: string
  isActive?: boolean
  isCrossed?: boolean
  className?: string
  children?: ReactNode
}

const Swatch = ({
  color,
  label,
  isActive = false,
  isCrossed = false,
  className,
  children,
}: SwatchProps) => {
  const borderColor = color.hex ? contrastColor({ bgColor: color.hex }) : ''

  const style: CSSPropertiesWithSwatch = {
    '--swatchColor': color.hex,
    '--swatchBorder': borderColor,
  }

  return (
    <div
      role="button"
      aria-label={label}
      className={cx(
        'relative p-0 block w-5 h-5',
        { 'shadow-border-black': isActive },
        className,
      )}
      style={{
        ...style,
        backgroundColor: 'var(--swatchColor)',
      }}
    >
      {children}
    </div>
  )
}

export default Swatch
