import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

interface ProductOptionThumbnailProps {
  thumbnail: SanityImageFragment
  width: number
  isActive?: boolean
  isCrossed?: boolean
}

const ProductOptionThumbnail = ({
  thumbnail,
  width,
  isActive = false,
  isCrossed = false,
}: ProductOptionThumbnailProps) => {
  // const dimensions = getImageDimensions(thumbnail)
  // const aspectRatio = dimensions ? dimensions.height / dimensions.width : 5 / 7

  const aspectRatio = 5 / 7
  const height = width * aspectRatio
  const image: SanityImageFragment = {
    ...thumbnail,
    customRatio: aspectRatio,
  }

  return (
    <div
      className={cx('relative', {
        'shadow-border-black': isActive,
      })}
    >
      <Photo
        image={image}
        width={width}
        height={height}
        showPlaceholder={false}
      />
      {isCrossed && <div className="absolute -inset-1 is-crossed" />}
    </div>
  )
}

export default ProductOptionThumbnail
