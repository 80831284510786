import cx from 'classnames'
import { useContext } from 'react'
import dynamic from 'next/dynamic'

import { type SanityModule } from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { ProductCombinedListingContext } from '@lib/product/combined-listing/context'
import { StringsContext } from '@lib/strings-context'

const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostItem = dynamic(() => import('./blog/blog-post-item'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const CombinedListingProductHero = dynamic(
  () => import('./shop/combined-listing-product-hero'),
)
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const RelatedProducts = dynamic(() => import('./shop/related-products'))
const SearchResultGrid = dynamic(() => import('./shop/search-result-grid'))
const SimpleGrid = dynamic(() => import('./simple-grid'))
const TeamMembersGrid = dynamic(() => import('./team-members-grid'))
const Video = dynamic(() => import('../components/video'))

interface ModuleProps {
  module: SanityModule
  collectionFeaturedProductIds?: number[]
  collectionProducts?: SanityProductFragment[]
  className?: string
}

const Module = ({
  module,
  collectionFeaturedProductIds,
  collectionProducts,
  className,
}: ModuleProps) => {
  const { activeCombinedListing } = useContext(ProductCombinedListingContext)
  const strings = useContext(StringsContext)

  switch (module._type) {
    case 'blogAuthorPostGrid': {
      const { title, author, posts } = module

      return (
        <BlogPostGrid
          title={title}
          author={author}
          posts={posts}
          className={className}
        />
      )
    }

    case 'blogCategoryPostGrid': {
      const { title, category, posts } = module

      return (
        <BlogPostGrid
          title={title}
          category={category}
          posts={posts}
          className={className}
        />
      )
    }

    case 'blogPostBody': {
      const { content } = module

      return <BlogPostBody content={content} className={className} />
    }

    case 'blogPostGrid': {
      const { title, posts } = module

      return <BlogPostGrid title={title} posts={posts} className={className} />
    }

    case 'blogPostHeader': {
      const { post } = module

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} className={className} />
    }

    case 'blogPostItem': {
      const { post } = module

      return <BlogPostItem post={post} className={className} />
    }

    case 'blogPostReadMore': {
      const { posts } = module

      return <BlogPostReadMore posts={posts} className={className} />
    }

    case 'cartForm': {
      const { active, cartSettings } = module

      if (!active || !cartSettings) {
        return null
      }

      return (
        <CartForm
          cartSettings={cartSettings}
          className={cx('max-w-3xl mx-auto py-6', className)}
        />
      )
    }

    case 'collectionGrid': {
      const {
        expandProducts,
        onlyDiscounts,
        showQuickFilters,
        collection,
        cartSettings,
        title,
        combinedListings,
      } = module

      if (collectionProducts && collectionProducts.length > 0) {
        return (
          <Collection
            title={title ?? strings.shopTitle}
            expandProducts={expandProducts}
            onlyDiscounts={onlyDiscounts}
            showQuickFilters={showQuickFilters}
            products={collectionProducts}
            featuredProductIds={collectionFeaturedProductIds ?? []}
            combinedListings={combinedListings}
            paginationLimit={cartSettings?.paginationLimit ?? 12}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
            className={className}
          />
        )
      }

      if (collection?.products && collection.products.length > 0) {
        return (
          <Collection
            title={title ?? collection.title}
            expandProducts={expandProducts}
            onlyDiscounts={onlyDiscounts}
            showQuickFilters={showQuickFilters}
            products={collection.products}
            productIds={collection.productIds}
            featuredProductIds={collection.featuredProductIds ?? []}
            combinedListings={combinedListings}
            paginationLimit={cartSettings?.paginationLimit ?? 12}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
            className={className}
          />
        )
      }

      return null
    }

    case 'dividerPhoto': {
      const { photo } = module

      return <DividerPhoto photo={photo} className={className} />
    }

    case 'grid': {
      const {
        columns,
        size,
        spacing,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
          className={className}
        />
      )
    }

    case 'hero': {
      const {
        bgType,
        contentPosition,
        content,
        photos,
        lottieAssetUrl,
        muxVideo,
      } = module

      return (
        <Hero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          lottieAssetUrl={lottieAssetUrl}
          muxVideo={muxVideo}
          overlay
          className={className}
        />
      )
    }

    case 'marquee': {
      const { content, contentAlignment, items, speed, reverse, pausable } =
        module

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
          className={className}
        />
      )
    }

    case 'predefinedPageModule': {
      const { pageModules } = module

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module
              key={pageModule._key}
              module={pageModule}
              className={className}
            />
          ))}
        </>
      )
    }

    case 'productHero': {
      const { active, shoppingInfo } = module

      if (!active) {
        return null
      }

      if (activeCombinedListing) {
        return (
          <CombinedListingProductHero
            shoppingInfo={shoppingInfo}
            className={className}
          />
        )
      }

      return <ProductHero shoppingInfo={shoppingInfo} className={className} />
    }

    case 'relatedProducts': {
      const { active, products, backupProducts } = module

      if (!active) {
        return null
      }

      return (
        <RelatedProducts
          products={products}
          backupProducts={backupProducts}
          className={className}
        />
      )
    }

    case 'searchResultGrid': {
      const { expandProducts, cartSettings } = module

      return (
        <SearchResultGrid
          expandProducts={expandProducts}
          paginationLimit={cartSettings?.paginationLimit ?? 8}
          className={className}
        />
      )
    }

    case 'simpleGrid': {
      const { blocks } = module

      return <SimpleGrid blocks={blocks} className={className} />
    }

    case 'teamMembersGrid': {
      const { title, teamMembers } = module

      return (
        <TeamMembersGrid
          title={title}
          teamMembers={teamMembers}
          className={className}
        />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = module

      return (
        <Video
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          className={className}
        />
      )
    }
  }
}

export default Module
