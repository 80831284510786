import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { parseOptionalParameters, parseRequiredParameter } from './helpers'

export interface UrlParameter {
  name: string
  value?: string | string[] | null
}

export type UrlParameters = Record<string, string[] | undefined>

export type UpdateUrlParameters = (parameters: UrlParameter[]) => void

export type TransformUrl = (url: string, parameters: UrlParameters) => string

interface UrlParameterOptions {
  transformUrl?: TransformUrl
  hideDefaultValue?: boolean
}

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (
  defaultUrlParameters: UrlParameter[],
  options?: UrlParameterOptions,
) => {
  const router = useRouter()

  // Get current URL query parameters
  const urlParameters = useMemo(
    () =>
      defaultUrlParameters.map((parameter) => ({
        name: parameter.name,
        value: router.isReady
          ? parseRequiredParameter(router.query[parameter.name]) ?? null
          : undefined,
      })),
    [defaultUrlParameters, router],
  )

  // Update URL query parameters on change
  const setUrlParameters = useCallback(
    (parameters: UrlParameter[]) => {
      let newParameters = [...parameters]

      if (options?.hideDefaultValue) {
        // Remove parameters that match default parameters (with both key and value)
        newParameters = newParameters.filter(
          (parameter) =>
            !defaultUrlParameters.some(
              (defaultUrlParameter) =>
                defaultUrlParameter.name === parameter.name &&
                defaultUrlParameter.value === parameter.value,
            ),
        )
      }

      const parameterObject = newParameters.reduce(
        (parameterObject, parameter) => {
          const value =
            parameter.value && Array.isArray(parameter.value)
              ? parameter.value
              : parameter.value?.split(',')

          return {
            ...parameterObject,
            [parameter.name]: value,
          }
        },
        {} as UrlParameters,
      )

      // Prepare a new relative URL (not starting with "/")
      const slugs = parseOptionalParameters(router.query.slug) ?? []
      let newUrl = slugs.join('/')

      newUrl = options?.transformUrl
        ? options.transformUrl(newUrl, parameterObject)
        : newUrl

      const urlQueryString = queryString.stringify(parameterObject, {
        arrayFormat: 'comma',
      })
      newUrl = `${newUrl}${urlQueryString ? `?${urlQueryString}` : ''}`

      router.replace(newUrl, undefined, {
        shallow: true,
        scroll: false,
      })
    },
    [defaultUrlParameters, options, router],
  )

  return [urlParameters, setUrlParameters] as const
}
