import Head from 'next/head'
import Script from 'next/script'
import { useEffect } from 'react'

import { type SanityGeneralSettings } from '@data/sanity/queries/types/site'
import { getRandomString } from '@lib/helpers'

import RawHtml from './raw-html'

interface ExternalScriptsProps {
  settings?: SanityGeneralSettings
}

const ExternalScripts = ({ settings }: ExternalScriptsProps) => {
  const nonce =
    typeof window !== 'undefined'
      ? document
          .querySelector('[property="csp-nonce"]')
          ?.getAttribute('content')
      : null
  const pageLoadEventId = getRandomString()

  // Execute scripts in embedded HTML
  useEffect(() => {
    if (!settings?.customHtml) {
      return
    }

    const div = document.createElement('div')
    div.innerHTML = settings.customHtml
    div.querySelectorAll('script').forEach((script) => {
      const newScript = document.createElement('script')
      newScript.textContent = script.textContent
      document.head.appendChild(newScript)
    })
  }, [settings])

  if (!nonce) {
    return null
  }

  return (
    <>
      {!!settings?.cookieBotId && (
        <Script
          id="Cookiebot"
          nonce={nonce}
          src="https://consent.cookiebot.com/uc.js"
          strategy="afterInteractive"
          data-cbid={settings.cookieBotId}
          data-blockingmode="auto"
        />
      )}

      {!!settings?.gtmContainerId && !!settings?.googleEvents && (
        <>
          <Script
            id="google-tag-manager-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              window['dataLayer'] = window['dataLayer'] || []
              window['dataLayer'].push({ eventId: '${pageLoadEventId}' })
              window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            `}
          </Script>
          <Script
            id="google-tag-manager"
            src={`https://www.googletagmanager.com/gtm.js?id=${settings.gtmContainerId}`}
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}

      {!!settings?.facebookPixelId && !!settings?.facebookEvents && (
        <>
          <Script
            id="facebook-pixel-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              if (!window.fbq) {
                const n = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                window.fbq = n

                if (!window._fbq) {
                  window._fbq = n
                }

                n.push = n
                n.loaded = true
                n.version = '2.0'
                n.queue = []

                window.fbq('init', '${settings.facebookPixelId}')
                window.fbq('track', 'PageView')
              }
            `}
          </Script>
          <Script
            id="facebook-pixel"
            src="https://connect.facebook.net/en_US/fbevents.js"
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}

      {!!settings?.cloudflareWebAnalyticsToken && (
        <Script
          id="cloudflare-web-analytics"
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon={`{"token": "${settings.cloudflareWebAnalyticsToken}"}`}
          nonce={nonce}
          strategy="afterInteractive"
        />
      )}

      {!!settings?.customHtml && (
        <Head>
          <RawHtml html={settings.customHtml} />
        </Head>
      )}
    </>
  )
}

export default ExternalScripts
