import cx from 'classnames'
import { useCallback, useContext, useMemo } from 'react'

import {
  type ProductOptionItem,
  getProductOptionClassName,
} from '@lib/product-options'
import { StringsContext } from '@lib/strings-context'

import RadioGroup from '@components/radio-group'
import SimpleLink from '@components/simple-link'
import ProductOption from './product-option'

interface ProductOptionListProps {
  productOptions: ProductOptionItem[]
  label?: string
  hasShowMore?: boolean
  showMoreUrl?: string
  isInProductCard?: boolean
  onSelect?: (optionValue: string) => void
  onPreview?: (optionValue?: string) => void
  className?: string
}

const ProductOptionList = ({
  productOptions,
  label,
  hasShowMore,
  showMoreUrl,
  isInProductCard,
  onSelect,
  onPreview,
  className,
}: ProductOptionListProps) => {
  const strings = useContext(StringsContext)

  const value = useMemo(() => {
    const selectedProductOption = productOptions.find(
      (productOption) => productOption.isSelected,
    )

    return selectedProductOption?.optionValue ?? ''
  }, [productOptions])

  const handleChange = useCallback(
    (newValue: string) => {
      if (onSelect) {
        onSelect(newValue)
      }
    },
    [onSelect],
  )

  return (
    <div
      className={cx(
        'flex',
        {
          'items-stretch': isInProductCard,
          'flex-col gap-y-2': !isInProductCard,
        },
        className,
      )}
    >
      {!!label && (
        <div className="text-xs uppercase">{`${label}: ${value}`}</div>
      )}

      <RadioGroup
        value={value}
        onChange={handleChange}
        className={cx('flex flex-wrap text-xs', {
          'gap-[1px]': !isInProductCard,
        })}
      >
        {productOptions.map((productOption) => (
          <ProductOption
            key={productOption.optionValue}
            value={productOption.optionValue}
            thumbnail={productOption.thumbnail}
            thumbnailWidth={isInProductCard ? 36 : 60}
            color={productOption.color}
            colorLabel={strings.productColorOptionLabel
              .replace(/{value}/gi, productOption.optionValue)
              .replace(/{name}/gi, productOption.optionName.toLowerCase())}
            isActive={productOption.isSelected}
            onMouseEnter={() => onPreview?.(productOption.optionValue)}
            onMouseLeave={() => onPreview?.()}
            className={getProductOptionClassName(
              productOption,
              isInProductCard,
            )}
          />
        ))}
      </RadioGroup>

      {!!hasShowMore && !!showMoreUrl && (
        <SimpleLink
          href={showMoreUrl}
          className="flex items-center justify-center leading-none w-9 border border-gray-light min-h-6"
        >
          +
        </SimpleLink>
      )}
    </div>
  )
}

export default ProductOptionList
