import { diff } from 'jsondiffpatch'
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { type CurrencyCode } from '@data/shopify/storefront/types'
import {
  type AnalyticsEvent,
  type EventPayload,
  type ShopifyEventPayload,
  AnalyticsEventName,
  AnalyticsEventType,
  getAnalyticsEvents,
  getProductEventPayload,
  triggerGoogleTagManagerEvent,
  triggerShopifyEvent,
} from './analytics'
import { CartContext } from './cart/context'
import { LanguageContext } from './language-context'
import { ProductContext } from './product-context'
import { ShopContext } from './shop-context'
import { getShopifyDomain } from './shopify/client'
import { SiteContext } from './site-context'

interface AnalyticsContextProps {
  triggerAnalyticsEvent: (
    eventName: AnalyticsEventName,
    eventPayload?: EventPayload,
  ) => void
}

interface AnalyticsProviderProps {
  children: ReactNode
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  triggerAnalyticsEvent: () => null,
})

/**
 * Finds an event that is ready to be processed.
 */
const getReadyEvent = (
  events: AnalyticsEvent[],
  shopId?: string,
  currencyCode?: CurrencyCode,
  cartId?: string,
) => {
  return events.find((event) => {
    if (event.type === AnalyticsEventType.GoogleTagManager) {
      return true
    }

    if (event.type === AnalyticsEventType.Shopify) {
      if (!shopId || !currencyCode) {
        return false
      }

      if (event.name === AnalyticsEventName.AddToCart && !cartId) {
        return false
      }

      return true
    }
  })
}

/**
 * Returns hook that triggers view product event on page load/navigation and active variant change.
 */
export const useViewProductEvent = () => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { product, activeVariant } = useContext(ProductContext)
  const { currencyCode } = useContext(ShopContext)

  const lastProductPageviewEventRef = useRef({})

  useEffect(() => {
    if (!product || !activeVariant || !currencyCode) {
      return
    }

    const eventPayload = getProductEventPayload(
      product.title,
      [
        {
          variantId: activeVariant.variantID,
          options: activeVariant.options,
          price: activeVariant.price,
          comparePrice: activeVariant.comparePrice,
          quantity: 1,
        },
      ],
      currencyCode,
    )

    // Make sure not to send the same event payload mulitple times
    const eventDifference = diff(
      lastProductPageviewEventRef.current,
      eventPayload,
    )

    if (!eventDifference) {
      return
    }

    triggerAnalyticsEvent(AnalyticsEventName.ViewProduct, eventPayload)
    lastProductPageviewEventRef.current = eventPayload
  }, [activeVariant, currencyCode, product, triggerAnalyticsEvent])
}

export const AnalyticsContextProvider = ({
  children,
}: AnalyticsProviderProps) => {
  const { settings } = useContext(SiteContext)
  const { cart } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopId, currencyCode } = useContext(ShopContext)

  const [eventQueue, setEventQueue] = useState<AnalyticsEvent[]>([])

  // Process event queue when events are added or other parameters change
  useEffect(() => {
    const event = getReadyEvent(eventQueue, shopId, currencyCode, cart?.id)

    if (!event) {
      return
    }

    // Remove event from queue
    setEventQueue((currentEventQueue) =>
      currentEventQueue.filter((eventItem) => eventItem.id !== event.id),
    )

    if (event.type === AnalyticsEventType.GoogleTagManager) {
      triggerGoogleTagManagerEvent(event.name, event.eventPayload)
    }

    if (event.type === AnalyticsEventType.Shopify) {
      triggerShopifyEvent(
        event.name,
        event.eventPayload as ShopifyEventPayload,
        shopId,
        currencyCode,
        cart?.id,
        getShopifyDomain(locale),
      )
    }
  }, [cart, currencyCode, eventQueue, locale, setEventQueue, shopId])

  const triggerAnalyticsEvent = useCallback(
    (eventName: AnalyticsEventName, eventPayload?: EventPayload) => {
      const events = getAnalyticsEvents(eventName, eventPayload ?? {}, {
        googleEvents: !!settings?.googleEvents,
        shopifyEvents: !!settings?.shopifyEvents,
      })

      setEventQueue((currentEventQueue) => [...currentEventQueue, ...events])
    },
    [setEventQueue, settings],
  )

  return (
    <AnalyticsContext.Provider
      value={{
        triggerAnalyticsEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
