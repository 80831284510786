import { useMemo } from 'react'

import {
  type SanityFilterGroup,
  type SanitySortOptionType,
} from '@data/sanity/queries/types/cart'
import { type SanityCombinedListingSettings } from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { compareNumbers, compareStrings, getAllCombinations } from './helpers'
import { type UrlParameter } from './hooks'
import { type OptionValue } from './product-options'
import { type Filter, getExpandedProducts } from './product'
import { getCombinedListingProductOptions } from './product/combined-listing/helpers'

export type CollectionProduct = SanityProductFragment & {
  preferredOption?: OptionValue
  variantIdProductSlugMap?: Record<number, string>
}

/**
 * Returns a method that compares products for sorting by price.
 */
const sortProductsByPrice = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareNumbers(product1.price, product2.price)
}

/**
 * Returns a method that compares products for sorting by title.
 */
const sortProductsByTitle = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareStrings(product1.title, product2.title)
}

/**
 * Returns a method that compares products for sorting by creation date.
 */
const sortProductsByDate = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareStrings(product1._createdAt, product2._createdAt)
}

/**
 * Collection filter and sort hook.
 */
export const useFilterAndSort = <
  T extends SanityProductFragment = SanityProductFragment,
>(
  products: T[],
  featuredProductIds: number[],
  filters: Filter[],
  sortValue: SanitySortOptionType | null,
) => {
  const filteredProducts = useMemo(() => {
    // Get all combinations of all filter values
    const filterCombinations = getAllCombinations(
      ...filters
        .filter((filter) => filter.values.length > 0)
        .map((filter) => filter.values),
    )

    const newFilteredProducts = products.filter((product) => {
      const productFilterValues =
        product.filters?.map((filter) => filter?.filter?.slug?.current) ?? []

      return filterCombinations.some((values) =>
        values.every((value) => productFilterValues.includes(value)),
      )
    })

    return newFilteredProducts
  }, [filters, products])

  switch (sortValue) {
    case 'priceAsc': {
      return filteredProducts.sort(sortProductsByPrice())
    }

    case 'priceDesc': {
      return filteredProducts.sort(sortProductsByPrice(true))
    }

    case 'alphaAsc': {
      return filteredProducts.sort(sortProductsByTitle())
    }

    case 'alphaDesc': {
      return filteredProducts.sort(sortProductsByTitle(true))
    }

    case 'dateAsc': {
      return filteredProducts.sort(sortProductsByDate())
    }

    case 'dateDesc': {
      return filteredProducts.sort(sortProductsByDate(true))
    }

    case 'featured': {
      const productIndices = featuredProductIds.reduce(
        (result: Record<number, number>, value: number, index: number) => (
          (result[value] = index + 1), result
        ),
        {},
      )

      return filteredProducts.sort(
        (product1, product2) =>
          (productIndices[product1.productID] ?? Infinity) -
          (productIndices[product2.productID] ?? Infinity),
      )
    }

    default: {
      return filteredProducts
    }
  }
}

/**
 * Gets sort parameter value from URL parameters.
 */
export const getSortParameterValue = (activeParameters: UrlParameter[]) => {
  const sortParameter = activeParameters.find(
    (activeParameter) => activeParameter.name === 'sort',
  )
  const value = sortParameter?.value

  if (!value || Array.isArray(value)) {
    return null
  }

  return value as SanitySortOptionType
}

/**
 * Returns a method that gets filter from a URL parameter.
 */
export const getFilterFromUrlParameter = (
  filterGroups: SanityFilterGroup[],
) => {
  return (activeParameter: UrlParameter) => {
    const activeParameterValues =
      activeParameter.value && Array.isArray(activeParameter.value)
        ? activeParameter.value
        : activeParameter.value?.split(',') ?? []

    const filterGroup = filterGroups.find(
      (filterGroup) => filterGroup.slug.current === activeParameter.name,
    )
    const optionSlugs =
      filterGroup?.options?.map((option) => option.slug.current) ?? []
    const valueSet = new Set(
      activeParameterValues.filter(
        (value) => Boolean(value) && optionSlugs.includes(value),
      ),
    )
    const values = [...valueSet]

    // Check if only one value is selected in this filter group and it contains a linked collection
    let productIds: number[] | undefined

    if (values.length === 1) {
      const option = filterGroup?.options.find(
        (option) => option.slug.current === values[0],
      )

      if (option?.collection?.featuredProductIds) {
        productIds = option?.collection?.featuredProductIds
      }
    }

    const filter: Filter = {
      name: activeParameter.name,
      values,
      productIds,
    }

    return filter
  }
}

/**
 * Returns a method that gets updated URL parameter from given new parameters.
 */
export const getUpdatedParameter = (newParameters: UrlParameter[]) => {
  return (parameter: UrlParameter): UrlParameter => {
    const matchedParameter = newParameters.find(
      (newParameter) => newParameter.name === parameter.name,
    )

    if (!matchedParameter) {
      return parameter
    }

    return {
      ...parameter,
      value: matchedParameter?.value ?? null,
    }
  }
}

export const mergeFilterSlugs = (products: SanityProductFragment[]) => {
  const slugs = new Set()

  products.forEach((product) => {
    if (product.filters) {
      product.filters.forEach((filter) => {
        const slug = filter?.filter?.slug?.current

        if (slug) {
          slugs.add(slug)
        }
      })
    }
  })

  return Array.from(slugs)
}

/**
 * Sorts products in given order of product IDs.
 */
export const getSortedProducts = (
  products: CollectionProduct[],
  featuredProductIds: string[],
) => {
  // Map product IDs to product indices in the sorted ID array
  const productIdIndexMap: Record<string, number> = {}

  featuredProductIds.forEach((productId, index) => {
    productIdIndexMap[productId] = index
  })

  return products.sort((product1, product2) => {
    const index1 = productIdIndexMap[product1._id]
    const index2 = productIdIndexMap[product2._id]

    if (typeof index1 === 'undefined' || typeof index2 === 'undefined') {
      return 0
    }

    return compareNumbers(index1, index2)
  })
}

/**
 * Gets filtered products and product options which have a discount.
 */
export const getOnlyDiscountedProducts = (products: CollectionProduct[]) => {
  const discountedProducts: CollectionProduct[] = []

  products.forEach((product) => {
    // Filter variants by compare price
    const variantsWithDiscount =
      product.variants?.filter((variant) => variant.comparePrice > 0) ?? []

    // Find option key-value pairs with discount
    const forOptionsWithDiscount: string[] = []
    variantsWithDiscount.forEach((variant) => {
      variant.options.forEach((option) => {
        const forOption = `${option.name}:${option.value}`

        if (!forOptionsWithDiscount.includes(forOption)) {
          forOptionsWithDiscount.push(forOption)
        }
      })
    })

    // Find gallery items, listing items, options and options settings by options which have a discount
    const galleryPhotosWithDiscount = product.galleryPhotos?.filter(
      (galleryPhoto) =>
        !galleryPhoto.forOption ||
        forOptionsWithDiscount.includes(galleryPhoto.forOption),
    )
    const listingPhotosWithDiscount = product.listingPhotos?.filter(
      (listingPhoto) =>
        !listingPhoto.forOption ||
        forOptionsWithDiscount.includes(listingPhoto.forOption),
    )
    const optionSettingsWithDiscount = product.optionSettings?.filter(
      (optionSetting) =>
        !optionSetting.forOption ||
        forOptionsWithDiscount.includes(optionSetting.forOption),
    )
    const optionsWithDiscount = product.options
      .map((option) => ({
        ...option,
        values: option.values.filter((value) => {
          const forOption = `${option.name}:${value}`

          return forOptionsWithDiscount.includes(forOption)
        }),
      }))
      .filter((option) => option.values.length > 0)

    if (variantsWithDiscount.length > 0) {
      discountedProducts.push({
        ...product,
        galleryPhotos: galleryPhotosWithDiscount,
        listingPhotos: listingPhotosWithDiscount,
        options: optionsWithDiscount,
        optionSettings: optionSettingsWithDiscount,
        variants: variantsWithDiscount,
      })
    }
  })

  return discountedProducts
}

/**
 * Gets compacted products where each product belonging to a combined listing is merged into a single product.
 */
export const getCompactedProducts = (
  products: CollectionProduct[],
  combinedListings?: SanityCombinedListingSettings[],
) => {
  // Create a mappings between combined listing IDs and product IDs
  const productIdCombinedListingMap: Record<string, string> = {}
  const combinedListingProductIdsMap: Record<string, string[]> = {}
  products.forEach((product) => {
    const combinedListing = combinedListings?.find((combinedListing) =>
      combinedListing.productIds?.some(
        (productId) => product._id === productId,
      ),
    )

    if (!combinedListing) {
      return
    }

    productIdCombinedListingMap[product._id] = combinedListing._id

    if (!combinedListingProductIdsMap[combinedListing._id]) {
      combinedListingProductIdsMap[combinedListing._id] = []
    }

    combinedListingProductIdsMap[combinedListing._id].push(product._id)
  })

  // Create compacted products from combined listing products
  const combinedListingCompactedProductMap: Record<string, CollectionProduct> =
    {}
  Object.entries(combinedListingProductIdsMap).forEach(
    ([combinedListingId, productIds]) => {
      const combinedListing = combinedListings?.find(
        (combinedListing) => combinedListing._id === combinedListingId,
      )
      const combinedListingProducts = productIds
        .map((productId) =>
          products.find((product) => product._id === productId),
        )
        .filter(Boolean) as CollectionProduct[]

      if (!combinedListing || combinedListingProducts.length === 0) {
        return
      }

      const galleryPhotos = combinedListingProducts.flatMap(
        (product) => product.galleryPhotos ?? [],
      )
      const listingPhotos = combinedListingProducts.flatMap(
        (product) => product.listingPhotos ?? [],
      )
      const optionSettings = combinedListingProducts.flatMap(
        (product) => product.optionSettings ?? [],
      )
      const variants = combinedListingProducts.flatMap(
        (product) => product.variants ?? [],
      )
      const options = getCombinedListingProductOptions(combinedListingProducts)

      // Create mapping of variant IDs to products slugs
      const variantIdProductSlugMap: Record<number, string> = {}
      combinedListingProducts.forEach((product) => {
        product.variants?.forEach((variant) => {
          variantIdProductSlugMap[variant.variantID] = product.slug.current
        })
      })

      combinedListingCompactedProductMap[combinedListingId] = {
        ...combinedListingProducts[0],
        title: combinedListing.title,
        galleryPhotos,
        listingPhotos,
        optionSettings,
        variants,
        options,
        variantIdProductSlugMap,
      }
    },
  )

  // Replace combined listing products with compacted products
  const compactedProducts: CollectionProduct[] = []
  products.forEach((product) => {
    const combinedListingId = productIdCombinedListingMap[product._id]

    if (!combinedListingId) {
      compactedProducts.push(product)
      return
    }

    // Skip products after the 1st product, only that is replaced with a compacted product
    const productIds = combinedListingProductIdsMap[combinedListingId]

    if (productIds[0] !== product._id) {
      return
    }

    compactedProducts.push(
      combinedListingCompactedProductMap[combinedListingId],
    )
  })

  return compactedProducts
}

/**
 * Gets collection product list from combined listing settings and other options.
 */
export const getCollectionProducts = (
  products: SanityProductFragment[],
  featuredProductIds?: string[],
  combinedListings?: SanityCombinedListingSettings[],
  expandProducts?: boolean,
  onlyDiscounts?: boolean,
) => {
  let collectionProducts: CollectionProduct[] = [...products]

  if (featuredProductIds && featuredProductIds.length > 0) {
    collectionProducts = getSortedProducts(
      collectionProducts,
      featuredProductIds,
    )
  }

  if (onlyDiscounts) {
    collectionProducts = getOnlyDiscountedProducts(collectionProducts)
  }

  if (expandProducts) {
    collectionProducts = getExpandedProducts(collectionProducts)
  }

  if (!expandProducts) {
    collectionProducts = getCompactedProducts(
      collectionProducts,
      combinedListings,
    )
  }

  return collectionProducts
}
