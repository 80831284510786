import {
  type SanityProductFragment,
  type SanityProductOption,
} from '@data/sanity/queries/types/product'
import { compareNumbers } from '@lib/helpers'

/**
 * Gets product options for combined listing products.
 */
export const getCombinedListingProductOptions = (
  products: SanityProductFragment[],
) => {
  const options: SanityProductOption[] = []

  // Merge options from all combined listing products
  products.forEach((product) => {
    product.options
      .filter((option) => option.values.length > 0)
      .forEach((option) => {
        const index = options.findIndex(
          (mergedOption) => mergedOption.name === option.name,
        )

        if (index === -1) {
          // If option doesn't exist in merged options, add it
          options.push(option)
          return
        }

        // If option does exist in merged options, update its list of values
        options[index].values = [
          ...options[index].values,
          ...option.values,
        ].filter((value, index, array) => array.indexOf(value) === index)
      })
  })

  // Sort options by postition
  return options.sort((option1, option2) =>
    compareNumbers(option1.position, option2.position),
  )
}
