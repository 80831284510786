import { type SanityNestedSimpleGridBlockItem } from '@data/sanity/queries/types/blocks'

import Freeform from '@blocks/freeform'
import LinkBlock from '@blocks/link-block'
import ProductCard from '@blocks/shop/product-card'
import Video from '@components/video'
import Photo from '@components/photo'

interface NestedSimpleGridBlockProps {
  block: SanityNestedSimpleGridBlockItem
}

const NestedSimpleGridBlock = ({ block }: NestedSimpleGridBlockProps) => {
  switch (block._type) {
    case 'figure': {
      return <Photo image={block} imageClassName="w-full" />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'linkBlock': {
      const {
        link,
        text,
        textColor,
        backgroundType,
        mobilePhoto,
        photo,
        muxVideo,
      } = block

      return (
        <LinkBlock
          link={link}
          text={text}
          textColor={textColor}
          backgroundType={backgroundType}
          mobilePhoto={mobilePhoto}
          photo={photo}
          muxVideo={muxVideo}
        />
      )
    }

    case 'productCard': {
      const { product } = block

      return (
        <ProductCard product={product} showThumbnails showOption showPrice />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = block

      return (
        <Video
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
        />
      )
    }
  }
}

export default NestedSimpleGridBlock
