import cx from 'classnames'

import { type SanityColor } from '@data/sanity/queries/types/color'
import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionSetting,
  type SanityProductVariantFragment,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { hasObject } from './helpers'
import { getVariantFromOptions } from './product'
import { getProductListingThumbnail } from './product-images'

import { ButtonVariant, getButtonStyles } from '@components/buttons/button'

export interface OptionValue {
  name: string
  value: string
}

export interface ProductOptionItem {
  optionName: string
  optionValue: string
  isSelected: boolean
  isWithVariants: boolean
  isInStock: boolean
  thumbnail?: SanityImageFragment
  color?: SanityColor
}

/**
 * Gets sorted and limited option values.
 */
const getOptionValues = (
  values: string[],
  preferredOptionValue?: string,
  optionLimit?: number,
) => {
  // Put preferred onption value first in option values
  const sortedOptionValues =
    preferredOptionValue && values.includes(preferredOptionValue)
      ? [
          preferredOptionValue,
          ...values.filter((value) => value !== preferredOptionValue),
        ]
      : values

  // Limit option value count
  const optionValues = optionLimit
    ? sortedOptionValues.slice(0, optionLimit)
    : sortedOptionValues

  return optionValues
}

/**
 * Gets product option list items.
 */
export const getProductOptions = (
  option: SanityProductOption,
  variantOptions: SanityProductVariantOption[],
  selectedOptionNames: string[] | null,
  galleryPhotos: SanityProductGalleryPhoto[],
  optionSettings: SanityProductOptionSetting[],
  variants: SanityProductVariantFragment[],
  preferredOptionValue?: string,
  optionLimit?: number,
) => {
  const isOptionSelected =
    selectedOptionNames === null || selectedOptionNames.includes(option.name)
  const variantOption = variantOptions.find(
    (variantOption) => variantOption.name === option.name,
  )
  const optionValues = getOptionValues(
    option.values,
    preferredOptionValue,
    optionLimit,
  )
  const otherOptions = variantOptions
    .filter(
      (variantOption) =>
        variantOption.position !== option.position ||
        variantOption.name !== option.name,
    )
    .map((variantOption) => {
      const optionValue: OptionValue = {
        name: variantOption.name,
        value: variantOption.value,
      }
      return optionValue
    })

  const productOptions = optionValues.map((optionValue) => {
    const newOption: OptionValue = {
      name: option.name,
      value: optionValue,
    }
    const newOptions = [newOption, ...otherOptions]

    const isOptionValueSelected =
      isOptionSelected && variantOption?.value === newOption.value

    const isWithVariants = variants.some((variant) =>
      variant.options.every((variantOption) =>
        hasObject(newOptions, variantOption),
      ),
    )

    const isInStock = variants.some((variant) => {
      if (!variant.inStock) {
        return false
      }

      return variant.options.every((variantOption) =>
        hasObject(newOptions, variantOption),
      )
    })

    // Graphics for options
    const optionSetting = optionSettings.find((optionSetting) => {
      const optionParts = optionSetting.forOption?.split(':')

      return (
        optionParts?.[0] === newOption.name &&
        optionParts?.[1] === newOption.value
      )
    })
    const thumbnail = getProductListingThumbnail(
      galleryPhotos,
      optionSetting?.forOption,
      optionSetting?.galleryImage,
    )
    const color = optionSetting?.color

    // Skip options that don't have a matching variant if they are after the 1st position (e.g., sizes that the color doesn't have)
    if (option.position > 1) {
      const variant = getVariantFromOptions(
        variants,
        variantOptions,
        newOption.name,
        newOption.value,
      )

      if (!variant) {
        return
      }
    }

    const productOption: ProductOptionItem = {
      optionName: newOption.name,
      optionValue: newOption.value,
      isSelected: isOptionValueSelected,
      isWithVariants,
      isInStock,
      thumbnail,
      color,
    }
    return productOption
  })
  return productOptions.filter(Boolean) as ProductOptionItem[]
}

/**
 * Gets class names for product option element.
 */
export const getProductOptionClassName = (
  productOption: ProductOptionItem,
  isInProductCard?: boolean,
) => {
  const isGraphic = !!productOption.thumbnail || !!productOption.color
  const isOutOfStock = productOption.isWithVariants && !productOption.isInStock

  const buttonStyles = getButtonStyles({
    variant: ButtonVariant.OUTLINED,
    isActive: productOption.isSelected,
  })

  return cx({
    'p-0 bg-transparent': isGraphic,
    'z-10': productOption.isSelected,
    'mr-1 [&:last-of-type]:mr-2': !isGraphic && isInProductCard,
    [`${buttonStyles} border-0 shadow-border-black`]:
      !isGraphic && !isInProductCard,
    'line-through': !isGraphic && isOutOfStock,
  })
}
