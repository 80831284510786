import { type SanityProductCarouselBlock } from '@data/sanity/queries/types/blocks'
import { getProductKey } from '@lib/product'

import ProductCard from '@blocks/shop/product-card'
import Carousel from '@components/carousel'

type ProductCarouselProps = Pick<
  SanityProductCarouselBlock,
  'title' | 'products'
> & {
  className?: string
}

const ProductCarousel = ({
  title,
  products,
  className,
}: ProductCarouselProps) => {
  return (
    <div className={className}>
      {title && <h2 className="pt-6 pb-2.5 px-3">{title}</h2>}

      {!!products && (
        <Carousel
          items={products.map((product) => (
            <ProductCard
              key={getProductKey(product)}
              product={product}
              showThumbnails
              showOption
              showPrice
            />
          ))}
        />
      )}
    </div>
  )
}

export default ProductCarousel
