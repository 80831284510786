import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useContext,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanitySimplePortableText } from '@data/sanity/queries/types/content'
import {
  AnalyticsEventName,
  getInitiateCheckoutEventPayload,
} from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { type ErrorMessages } from '@lib/helpers'
import { type Locale } from '@lib/language'
import { LanguageContext } from '@lib/language-context'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import SimplePortableText from '@components/simple-portable-text'

/**
 * Formats checkout URL by replacing host and adding linker param.
 */
const formatCheckoutUrl = async (
  checkoutUrl: string,
  locale: Locale,
  storeUrl?: string,
) => {
  let formattedCheckoutUrl = `${checkoutUrl}`

  // Update checkout URL to use custom domain name
  if (storeUrl) {
    formattedCheckoutUrl = formattedCheckoutUrl.replace(
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
      storeUrl,
    )
  }

  // Add locale parameter to URL
  const searchParams = new URLSearchParams(`locale=${locale}`)
  const newUrl = new URL(formattedCheckoutUrl)
  searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))
  formattedCheckoutUrl = newUrl.toString()

  // TODO: Add Google Analytics linker param to URL.

  return formattedCheckoutUrl
}

interface CartSubmitProps {
  id?: string
  vatId: string
  comment: string
  terms?: SanitySimplePortableText
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  storeUrl?: string
  onClick?: () => void
}

const CartSubmit = ({
  id,
  vatId,
  comment,
  setErrorMessages,
  storeUrl,
  onClick,
  terms,
}: CartSubmitProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { cart, isCartUpdating, isCartSubmitting, submitCart } =
    useContext(CartContext)
  const { currencyCode } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const { register, watch } = useForm()
  const acceptTermsRegister = register('acceptTerms')
  const hasAgreed = watch('acceptTerms')

  // Update checkout URL to use custom domain name
  const checkoutUrl = cart?.webUrl

  const goToCheckout = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const { errors } = await submitCart({ vatId, comment })

    setErrorMessages((messages) => ({ ...messages, vatId: errors.vatId }))

    if (checkoutUrl && Object.entries(errors).length === 0) {
      onClick && onClick()

      // Trigger initiate checkout event
      const eventPayload = getInitiateCheckoutEventPayload(cart, currencyCode)
      triggerAnalyticsEvent(AnalyticsEventName.InitiateCheckout, eventPayload)

      // Redirect to checkout
      setTimeout(async () => {
        const formattedCheckoutUrl = await formatCheckoutUrl(
          checkoutUrl,
          locale,
          storeUrl,
        )
        window.location.href = formattedCheckoutUrl
      }, 0)
    }
  }

  return (
    <div className="flex flex-col gap-y-4">
      {terms && (
        <Checkbox
          id={`${id ? id : ''}-cart-acceptTerms`}
          formRegister={acceptTermsRegister}
        >
          <SimplePortableText content={terms} />
        </Checkbox>
      )}

      <Button
        variant={ButtonVariant.FILLED}
        className="w-full"
        disabled={isCartUpdating || isCartSubmitting || (terms && !hasAgreed)}
        onClick={goToCheckout}
      >
        {isCartUpdating && strings.buttonUpdating}
        {isCartSubmitting && strings.buttonSubmitting}
        {!isCartUpdating && !isCartSubmitting && strings.cartSubmit}
      </Button>
    </div>
  )
}

export default CartSubmit
