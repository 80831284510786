import { sha256 } from 'js-sha256'
import { useRouter } from 'next/router'
import { BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { type SanitySignupFormStrings } from '@data/sanity/queries/types/blocks'
import { ParseStatus } from '@lib/shopify/graphql/client'
import { createUser } from '@lib/shopify/graphql/customer'
import { type EventPayload, AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { SignupFormValues, useLoginUser } from '@lib/auth'
import { ErrorMessages } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl, getPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'
import ComplexPortableText from '@components/complex-portable-text'
import ButtonLink from '@components/buttons/button-link'

interface SignupFormProps {
  className?: string
  signupFormStrings: SanitySignupFormStrings
}

const SignupForm = ({ signupFormStrings, className }: SignupFormProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { shopifyStorefrontClient } = useContext(ShopContext)

  const router = useRouter()
  const loginUser = useLoginUser()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<SignupFormValues>()

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  // Handle form submission
  const onSubmit = async (
    signupFormValues: SignupFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoading(true)
    setIsError(false)

    // Create user
    const createUserResult = await createUser(
      shopifyStorefrontClient,
      signupFormValues,
    )
    setErrorMessages(createUserResult.fieldErrors)

    if (createUserResult.status !== ParseStatus.OK) {
      setIsError(true)
    }

    // Trigger signup event
    const eventPayload: EventPayload = {
      user_data: {
        sha256_email_address: sha256(signupFormValues.email),
        address: {
          first_name: signupFormValues.firstName,
          last_name: signupFormValues.lastName,
        },
      },
    }
    triggerAnalyticsEvent(AnalyticsEventName.Signup, eventPayload)

    if (
      createUserResult.status === ParseStatus.OK &&
      createUserResult.errorCount === 0
    ) {
      const { email, password } = signupFormValues

      // Login after creating user
      const loginUserResult = await loginUser({
        email,
        password,
      })

      setErrorMessages(loginUserResult.fieldErrors)

      if (loginUserResult.status !== ParseStatus.OK) {
        setIsError(true)
      }

      if (
        loginUserResult.status === ParseStatus.OK &&
        loginUserResult.errorCount === 0
      ) {
        reset()

        // Redirect to account after logging in
        const url = getLinkPageUrl('accountPage')
        router.push(url, url, {
          locale,
        })
      }
    }

    setIsLoading(false)
  }

  const firstNameRegister = register('firstName', {
    required: signupFormStrings.signupFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: signupFormStrings.signupLastNameMissing,
  })
  const emailRegister = register('email', {
    required: signupFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const passwordRegister = register('password', {
    required: signupFormStrings.signupPasswordMissing,
  })

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.password

  const loginPageUrl = getPageUrl(locale, 'loginPage')

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('flex flex-col gap-y-12', className)}
    >
      <h1 className="is-large">{signupFormStrings.signupTitle}</h1>

      <div className="flex flex-col gap-y-6">
        <InputField
          id="signup-fist-name"
          type="text"
          formRegister={firstNameRegister}
          errorMessage={errorMessages?.firstName ?? errors.firstName?.message}
          label={signupFormStrings.signupFirstName}
          placeholder={signupFormStrings.signupFirstNamePlaceholder}
        />

        <InputField
          id="signup-last-name"
          type="text"
          formRegister={lastNameRegister}
          errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
          label={signupFormStrings.signupLastName}
          placeholder={signupFormStrings.signupLastNamePlaceholder}
        />

        <InputField
          id="signup-email"
          type="email"
          autoComplete="email"
          formRegister={emailRegister}
          errorMessage={errorMessages?.email ?? errors.email?.message}
          label={signupFormStrings.signupEmail}
          placeholder={signupFormStrings.signupEmailPlaceholder}
        />

        <InputField
          id="signup-password"
          type="password"
          autoComplete="off"
          formRegister={passwordRegister}
          errorMessage={errorMessages?.password ?? errors.password?.message}
          label={signupFormStrings.signupPassword}
          placeholder={signupFormStrings.signupPasswordPlaceholder}
        />
      </div>

      <div className="flex flex-col gap-y-6">
        {isError && (
          <div key="error">
            <Alert error className="rc rc-alert rc-error">
              <ComplexPortableText
                content={signupFormStrings.signupErrorMessage}
              />
            </Alert>
          </div>
        )}

        <Button
          type="submit"
          variant={ButtonVariant.FILLED}
          disabled={isLoading || isDisabled}
          className="w-full"
        >
          {isLoading
            ? strings.buttonSubmitting
            : signupFormStrings.signupSubmit}
        </Button>

        <ButtonLink href={loginPageUrl} className="self-center uppercase">
          {signupFormStrings.signupLoginLink}
        </ButtonLink>
      </div>
    </form>
  )
}

export default SignupForm
