import groq from 'groq'

import { blogPostWithoutBodyFragment } from './blog'
import { imageFragment } from './image'
import { moduleFragment } from './modules'
import { productFragment } from './product'
import { siteFragment } from './site'

export const allSlugsQuery = groq`
  *[
    _type == $type &&
    type != "template"
  ] {
    locale,
    slug,
  }
`

export const allProductSlugsQuery = groq`
  *[
    _type == "product" &&
    !wasDeleted &&
    !isDraft
  ] {
    locale,
    slug,
  }
`

export const sitemapPagesQuery = groq`
  *[
    _type == $type &&
    locale in $locales &&
    blockCrawling != true &&
    type != "template"
  ] {
    _type,
    locale,
    slug,
  }
`

export const sitemapStaticPagesQuery = groq`
  *[
    _type in $types &&
    locale in $locales &&
    blockCrawling != true
  ] {
    _type,
    locale,
  }
`

export const crawlingBlockedPagesQuery = groq`
  *[
    !(_type in ["errorPage"]) &&
    blockCrawling == true &&
    type != "template"
  ] {
    _type,
    locale,
    slug,
  }
`

export const shopPageQuery = groq`
  {
    "page": *[
      _type == "shopPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
      "featuredProductIds": featuredProducts[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        !wasDeleted &&
        !isDraft
      ]
        | order(title asc)
      {
        ${productFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const shopPageModuleQuery = groq`
  *[
    _type == "shopPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const shopSalePageQuery = groq`
  {
    "page": *[
      _type == "shopSalePage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
      "featuredProductIds": featuredProducts[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        !wasDeleted &&
        !isDraft
      ]
        | order(title asc)
      {
        ${productFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const shopSalePageModuleQuery = groq`
  *[
    _type == "shopSalePage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const shopSearchPageQuery = groq`
  {
    "page": *[
      _type == "shopSearchPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const shopSearchPageModuleQuery = groq`
  *[
    _type == "shopSearchPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const cartPageQuery = groq`
  {
    "page": *[
      _type == "cartPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const cartPageModuleQuery = groq`
  *[
    _type == "cartPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const homePageQuery = groq`
  {
    "page": *[
      _type == "homePage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      heroLogo {
        ${imageFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const homePageModuleQuery = groq`
  *[
    _type == "homePage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const errorPageQuery = groq`
  {
    "page": *[
      _type == "errorPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const errorPageModuleQuery = groq`
  *[
    _type == "errorPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const signupPageQuery = groq`
  {
    "page": *[
      _type == "signupPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const signupPageModuleQuery = groq`
  *[
    _type == "signupPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const loginPageQuery = groq`
  {
    "page": *[
      _type == "loginPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const loginPageModuleQuery = groq`
  *[
    _type == "loginPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const passwordRecoveryPageQuery = groq`
  {
    "page": *[
      _type == "passwordRecoveryPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const passwordRecoveryPageModuleQuery = groq`
  *[
    _type == "passwordRecoveryPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const accountPageQuery = groq`
  {
    "page": *[
      _type == "accountPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const accountPageModuleQuery = groq`
  *[
    _type == "accountPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const accountAddressPageQuery = groq`
  {
    "page": *[
      _type == "accountAddressPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const accountAddressPageModuleQuery = groq`
  *[
    _type == "accountAddressPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const customPageQuery = groq`
  {
    "page": *[
      _type == "page" &&
      slug.current in $pageSlugs &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      type,
      seo,
      webPageSchema,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const customPageModuleQuery = groq`
  *[
    _type == "page" &&
    slug.current in $pageSlugs &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogPostPageQuery = groq`
  {
    "page": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      type,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      slug,
      "blogPage": *[
        _type == "blogPage"
      ]
        | order(_updatedAt desc)
      [0] {
        seo,
      },
      ${blogPostWithoutBodyFragment}
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPostPageModuleQuery = groq`
  *[
    _type == "blogPost" &&
    type != "template" &&
    slug.current == $blogPostSlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogPageQuery = groq`
  {
    "page": *[
      _type == "blogPage" &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPageModuleQuery = groq`
  *[
    _type == "blogPage" &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogCategoryPageQuery = groq`
  {
    "page": *[
      _type == "blogCategory" &&
      slug.current == $blogCategorySlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogCategoryPageModuleQuery = groq`
  *[
    _type == "blogCategory" &&
    slug.current == $blogCategorySlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogAuthorPageQuery = groq`
  {
    "page": *[
      _type == "blogAuthor" &&
      slug.current == $blogAuthorSlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogAuthorPageModuleQuery = groq`
  *[
    _type == "blogAuthor" &&
    slug.current == $blogAuthorSlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const productPageQuery = groq`
  {
    "page": *[
      _type == "product" &&
      slug.current == $productSlug &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft
    ]
      | order(_updatedAt desc)
    [0] {
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      "combinedListing": *[
        _type == "productCombinedListing" &&
        locale == $locale &&
        ^._id in products[]._ref
      ]
        | order(_updatedAt desc)
      [0] {
        title,
        products[]->{
          ${productFragment}
        },
        modules[] {
          ${moduleFragment}
        },
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const productPageModuleQuery = groq`
  *[
    _type == "product" &&
    slug.current == $productSlug &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const collectionPageQuery = groq`
  {
    "page": *[
      _type == "collection" &&
      slug.current == $collectionSlug &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      title,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      "shopPage": *[
        _type == "shopPage"
      ]
        | order(_updatedAt desc)
      [0] {
        seo,
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const collectionPageModuleQuery = groq`
  *[
    _type == "collection" &&
    slug.current == $collectionSlug &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`
